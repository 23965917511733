import { getBrowserLocale } from '@/libs';
import { isLoginChallengeRequired } from '@/libs/ChallengeHelper';
import { logger } from '@/libs/logger';
import AppConfig from '@config';
import { Company } from '../models/models';
import { parseCompany } from '../models/parsers';
import { dispatchGetGuest } from './guests';
import {
  dispatchCompanies,
  getAccessTokenForInitialToken,
  processResponse,
  setLocalStorageForInitialToken,
  validateCompany,
} from './utils';

const getToken = async () => {
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/access_token`,
    {
      method: 'POST',
      body: JSON.stringify({
        grant_type: 'client_credentials',
        client_id: AppConfig.Settings.CLIENT_ID,
        client_secret: AppConfig.Settings.SECRET_KEY,
        scope: 'portal-guest-client',
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    },
  );

  return processResponse(response);
};

const fetchCompany = async ({
  companyId,
  reservationId,
  accessToken,
}: {
  companyId: string;
  reservationId: string;
  accessToken: string;
}) => {
  // a hack for vrscheduler OR the external ID that has @sign
  reservationId = reservationId.replace('%40', '@');
  reservationId = reservationId.replace('%2540', '@');

  const locale = getBrowserLocale() ?? 'en';

  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/companies/${companyId}/config/${reservationId}?version=2&locale=${locale}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return processResponse(response);
};

export const dispatchGetCompany = async (configId: string) => {
  // id contains the company_reservation string
  const splitIndex = configId.indexOf('_');
  const companyId = configId.slice(0, splitIndex);
  const reservationId = configId.slice(splitIndex + 1);
  // 1st leg of the call to get a valid access token.
  // either use existing valid one or fetch new one
  let accessToken: string;
  try {
    accessToken = getAccessTokenForInitialToken(reservationId);
  } catch (error) {
    const json = await getToken();
    accessToken = json?.data?.access_token;
    const expiredIn =
      Math.floor(Date.now() / 1000) + json?.data?.expires_in * 1000;
    setLocalStorageForInitialToken(reservationId, 'token', accessToken);
    setLocalStorageForInitialToken(
      reservationId,
      'expiry',
      expiredIn.toString(),
    );
  }

  // 2nd leg is to use the valid accessToken to get company
  const json = await fetchCompany({
    companyId,
    reservationId,
    accessToken,
  });

  if (validateCompany(json?.data) === false) {
    logger.info('error validating company', JSON.stringify(json));
    throw new Error('Reservation does not matched or expired');
  }

  const company: Company = parseCompany({
    accessToken,
    companyId,
    data: json?.data,
    reservationId,
  });

  await dispatchCompanies([company]);

  if (
    !isLoginChallengeRequired(company) &&
    (!company.newLoginEnabled || company.isTestReservation)
  ) {
    await dispatchGetGuest(company);
  }
};

export const dispatchUpdateCompany = (company: Company) =>
  dispatchCompanies([company]);

export const dispatchGetQRCodeWelcomeMessages = async (companyId: string) => {
  // TODO: NOT IN USED. IF NEEDED TO BE USED, IMPLEMENT WITH COGNITO SUPPORT IN BE
  const response = await fetch(
    `${AppConfig.Settings.BASE_API_URL}/portal/companies/${companyId}/welcome-messages`,
  );

  const json = await processResponse(response);
  const company: Company = parseCompany({
    accessToken: '',
    reservationId: '',
    companyId,
    data: json?.data,
  });

  await dispatchCompanies([company]);
};
